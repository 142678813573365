<template>
  <div
    v-click-outside="close"
    class="change-password-popup popup"
  >
    <div class="popup-container">
      <div class="popup-title d-flex justify-content-center">
        <span v-t="{path: 'CHANGE_PASSWORD.TITLE'}"/>
      </div>
      <div class="animated fadeIn">
        <form
          class="need-validated"
          autocomplete="on"
          @submit.prevent.stop
        >
          <div>
            <h5
              v-for="(mess, index) in messages"
              :key="index"
              class="popup-message text-center mt-4 mb-4"
              :class="'message-' + mess.status"
              v-html="mess.text"
            />
          </div>

          <div class="popup-form-group">
            <label for="old-password"/>
            <input
              id="old-password"
              v-model="formData.oldPassword"
              type="password"
              name="old-password"
              autocomplete="current-password"
              class="form-control"
              :placeholder="$t('CHANGE_PASSWORD.OLD_PAS')"
              required="required"
              minlength="6"
            >
          </div>
          <div class="form-group popup-form-group">
            <label for="new-password"/>
            <input
              id="new-password"
              v-model="formData.newPassword"
              type="password"
              name="new-password"
              class="form-control"
              :placeholder="$t('CHANGE_PASSWORD.NEW_PAS')"
              required="required"
              minlength="6"
            >
          </div>
          <div class="form-group popup-form-group">
            <label for="re-entered-password"/>
            <input
              id="re-entered-password"
              v-model="formData.repeatedPassword"
              type="password"
              name="re-entered-password"
              class="form-control"
              :placeholder="$t('CHANGE_PASSWORD.REPEAT_PAS')"
              required="required"
              minlength="6"
            >
          </div>

          <div class="d-flex form-group justify-content-between mt-4">
            <button
              class="btn rounded popup-form-btn popup-form-btn--cancel"
              @click.stop="close"
            >
              <span class="d-inline-block text-truncate align-middle w-100">
                <span v-t="{path: 'CHANGE_PASSWORD.BUTTONS.CANCEL'}"/>
              </span>
            </button>
            <button
              :disabled="waiting"
              class="btn rounded popup-form-btn"
              @click="submitForm"
            >
              <span class="d-inline-block text-truncate align-middle w-100">
                <span v-t="{path: 'CHANGE_PASSWORD.BUTTONS.CONFIRM'}"/>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import vClickOutside from "v-click-outside";
  import { amsClient } from 'src/service/ams';

  export default {
    name: 'ChangePassword',
    directives: {
      clickOutside: vClickOutside.directive,
    },
    data() {
      return {
        formData: {
          oldPassword: '',
          newPassword: '',
          repeatedPassword: '',
        },
        messages: [],
        waiting: false,
      }
    },
    created() {
    },
    methods: {
      close() {
        this.$emit('close-popup');
      },
      submitForm: function () {
        if (
          this.formData.oldPassword.length < 6 ||
          this.formData.newPassword.length < 6 ||
          this.formData.repeatedPassword.length < 6
        ) return;

        amsClient.callAms('/accounts/change_password/', {
          method: 'post',
          body: JSON.stringify({
            'old_password': this.formData.oldPassword,
            'new_password': this.formData.newPassword,
            'new_password_repeat': this.formData.repeatedPassword,
          }),
        })
          .then(() => {
            this.waiting = true;
            const success_message = this.$t('CHANGE_PASSWORD.SUCCESS')
            this.showMessage(
              {
                status: 'success',
                text: success_message,
              },
              3000,
              this.close,
            );
          })
          .catch(error => {
            let message;
            switch (error.message) {
              case 'New passwords are different':
                message = this.$t('CHANGE_PASSWORD.NEW_PASS_DIFF');
                break;
              case 'Old password is incorrect':
                message = this.$t('CHANGE_PASSWORD.OLD_PASS_INCORRECT');
                break;
              default:
                message = error.message;
            }
            this.showMessage(
              {
                status: 'warning',
                text: message,
              },
              3000,
            );
          })
      },
      showMessage(messageData, time, callback) {
        this.messages.push(messageData);
        setTimeout(() => {
          let pos = this.messages.indexOf(messageData);
          this.messages.splice(pos, 1);
          if (callback) {
            callback()
          }
        }, time || this.timeToShowMessage)
      },
    },
  };
</script>

<style scoped lang="scss">
	@import './change-password.scss';
</style>